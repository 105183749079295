import React, { Component } from "react";
import AdsService from "../services/ads";

const AdContext = React.createContext();

export default class AdContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spots: [],
    };
  }

  componentDidMount() {
    AdsService.getSpots().then((response) => {
      this.setState({
        spots: response.data.data,
      });
    });
  }

  render() {
    return (
      <AdContext.Provider value={this.state}>
        {this.props.children}
      </AdContext.Provider>
    );
  }
}

const AdContextConsumer = AdContext.Consumer;
export { AdContext, AdContextConsumer };
